import React from 'react'
import './header.css'
const Footer = () => {
  return (
    <div className='footer'>
      <p>Terms & Conditions  +</p>
    </div>
  )
}

export default Footer
