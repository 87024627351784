import React, { useEffect } from 'react'
import Head1 from '../../components/header/Head1'
import Foot1 from '../../components/header/Foot1'
import './otp.css'
import { useNavigate } from 'react-router-dom'
import s1 from '../../images/s1.png'
import load from '../../images/load.gif'
const Otp = () => {
    const navigate=useNavigate()
    useEffect(()=>{
        setTimeout(()=>{
navigate("/otp1")
        },4000)
    })
  return (
    <div className='otp'>
      <Head1/>
      <div className="otps">
        <p>please wait while we are processing payment </p>
        <img src={load} alt="" className="gif" />
        <img src={s1} alt="" />
      </div>
      <Foot1/>
    </div>
  )
}

export default Otp
