import React from 'react'
import Head1 from '../../components/header/Head1'
import Foot1 from '../../components/header/Foot1'
import method from '../../images/method.png'
import metho1 from '../../images/protection.png'
import { useNavigate } from 'react-router-dom'
import './option.css'
const Option = () => {
  const navigate=useNavigate()
  const conClick=()=>{
    navigate("/payment")
  }
  return (
    <div className='option'>
      <Head1/>
      <p style={{padding:'10px',fontSize:'22px',color:'black',fontWeight:600,marginBottom:0}}>Select A Payment Method</p>
      <div className="methods">
        <div className="img">
<input type="radio" name="" id="" defaultChecked/>
<img src={method} alt="" />
        </div>
        <div className="card">
        <p>Add Debit/Credit/ATM Card</p>
<p>You can save your cards as per new RBI guidelines. <span>Learn More</span></p>
        </div>
        <div className="options">
<div className="emi">
<input type="radio" name="" id="" disabled/>
<p>EMI Unavailable Why?</p>
</div>
<div className="pay">
<input type="radio" name="" id="" disabled/>
<p>Pay on Delivery</p>
</div>
        </div>
      </div>
      <div className="btns">
        <button onClick={conClick}>Continue</button>
      </div>
      <img src={metho1} alt="" className='m1'/>
      <Foot1/>
    </div>
  )
}

export default Option
