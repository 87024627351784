import React from 'react'
import './header.css'
import SearchIcon from '@mui/icons-material/Search';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
const Header = () => {
  return (
    <div className='header'>
      <div className="header1">
        <div className="logo">
            <img src="https://pngimg.com/d/amazon_PNG25.png" alt="" />
        </div>
        <div className="search">
            <input type="text" name="" id="" placeholder='Search Amazon.in'/>
<SearchIcon className='s-icon'/>
        </div>
        <div className="cart">
<ProductionQuantityLimitsIcon className='c-icon'/>
<p>1</p>
        </div>
      </div>
      <div className="header2">
        <p>Top Deals</p>
        <p>Electronics</p>
        <p>Mobile</p>
        <p>Sell</p>
        <p>Best Seller</p>

      </div>
    </div>
  )
}

export default Header
