import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import foot from '../../images/foot.png'
import secure from '../../images/secure.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import './singlep.css'
import Header from '../../components/header/Header'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/header/Footer'

const SingleP = () => {
  const navigate=useNavigate()

  const product=JSON.parse(localStorage.getItem("product"))
  const addCart=()=>{
    navigate("/address")
  }
  return (
    <div className='singlep'>
      <Header/>
      <div className="carousel">
        <p>{product?.detail?.discount}% Off</p>
        
        <Carousel controls={false} interval={1000}>
          {
            product?.images?.map((item)=>{
              return <Carousel.Item>
                               <img src={item} alt="" />

              </Carousel.Item>
            })
          }
     
     
    </Carousel>

      </div>
      <div className="details">
<p className="name">{product?.detail?.name}</p>
<p className="price">&#8377;{product?.detail?.price}</p>
<p className="mrp"><span>&#8377;{product?.detail?.mrp}</span><span>Save {product?.detail?.discount}% off</span></p>
      </div>
      <div className="secure">
        <img src={secure} alt="" />
      </div>
      <div className="shots">
<img src={product?.shot} alt="" />
      </div>
      <div className="foot">
        <img src={foot} alt="" />
      </div>
      <div className="btns">
<button onClick={addCart}>Add to Cart</button>
<button onClick={addCart}>Buy Now</button>
      </div>
      <Footer/>
    </div>
  )
}

export default SingleP
