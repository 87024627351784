import React,{useState,useEffect} from 'react'
import './home.css'
import {products} from '../../data'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/header/Header'
import Footer from '../../components/header/Footer'
import h1 from '../../images/h1.png'
const Home = () => {
    const [timeLeft, setTimeLeft] = useState(900); // 15 minutes in seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    // Clean up the interval on unmount
    return () => clearInterval(timer);
  }, []);

  // Convert remaining time to minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const navigate=useNavigate()
const buyNow=(item)=>{
  localStorage.setItem("product",JSON.stringify(item))
  navigate(`/product/${item?.detail?.Home}`)
}
  return (
    <div className="home">
      <Header/>
        <div className="hero">
            <img src="https://images-eu.ssl-images-amazon.com/images/G/31/AmazonBusiness/980_AB_GIF_Wave04_SP_TopBanner_Unrec_1242x450.jpg" alt="" />
            <img src={h1} alt="" />
        </div>
        <div className="count">
            <div className="time">
                <p>Deals Of The Day</p>
                <p> {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
                <button>View All</button>
            </div>
        </div>

        <div className="products">
          {
            products?.map((item,index)=>{
              return <div className="product">
              <div className="img">            <img src={item?.images[0]} alt="" />
  </div>                      <p className='name'>{item?.detail?.name}</p>
                  <p className='off'><span>&#8377;{item?.detail?.mrp}</span><span>{item?.detail?.discount}% off</span></p>
                  <img src="https://download.logo.wine/logo/Amazon_Prime/Amazon_Prime-Logo.wine.png" alt="" className='prime'/>
                  <p className='price'>NOW &#8377;{item?.detail?.price}</p>
                  <button onClick={()=>buyNow(item)}>Buy Now</button>
              </div>
            })
          }
            
            
        </div>
        <Footer/>
    </div>
  )
}

export default Home
