import React from 'react'

const Foot1 = () => {
  return (
    <div className='foot1'>
        <div>
            <p>conditions of use</p>
            <p>Privacy Notice</p>
            <p>Interest-based Ads</p>
        </div>
        <p>© 1996-2023, Amazon.com, Inc. and its affiliates
</p>
      
    </div>
  )
}

export default Foot1
