import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'
import img5 from './images/5.png'
import img6 from './images/6.png'
import img7 from './images/7.png'
import img8 from './images/8.png'
import img9 from './images/9.png'
import img10 from './images/10.png'
import img11 from './images/11.png'
import img12 from './images/12.png'
import img13 from './images/13.png'
import img14 from './images/14.png'
import img15 from './images/15.png'
import img16 from './images/16.png'
import img17 from './images/17.png'
import img18 from './images/18.png'
import img19 from './images/19.png'
import img20 from './images/1.png'

export const products=[
    {images:["https://m.media-amazon.com/images/I/71XP2ScwtFL._SX679_.jpg","https://m.media-amazon.com/images/I/71QH5ocnK-L._SX569_.jpg","https://m.media-amazon.com/images/I/816mv34yvzL._SX569_.jpg","https://m.media-amazon.com/images/I/71bsC7j+kGL._SX679_.jpg","https://m.media-amazon.com/images/I/81N5OqyNlwL._SX679_.jpg"],detail:{name:"boAt Nirvana 751 ANC Hybrid Active Noise Cancelling Bluetooth Wireless Over Ear Headphones with Up to 65H Playtime, ASAP Charge, Ambient Sound Mode, Immersive Sound, Carry Pouch with mic (Bold Blue)",price:179,mrp:3999,discount:99},shot:img1},
    {images:["https://m.media-amazon.com/images/I/61PJk4fAcYL._SX679_.jpg","https://m.media-amazon.com/images/I/61qzknTpa1L._SX679_.jpg","https://m.media-amazon.com/images/I/61JumHy9YEL._SX679_.jpg","https://m.media-amazon.com/images/I/61LdvRS1RQL._SX679_.jpg","https://m.media-amazon.com/images/I/61y58Gl8TeL._SX679_.jpg"],detail:{name:"boAt Airdopes 170 TWS Earbuds with 50H Playtime, Quad Mics ENx™ Tech, Low Latency Mode, 13mm Drivers, ASAP™ Charge, IPX4, IWP™, Touch Controls & BT v5.3(Classic Black)",price:69,mrp:1299,discount:89},shot:img2},
    {images:["https://m.media-amazon.com/images/I/516GiFNOpbL._SX679_.jpg","https://m.media-amazon.com/images/I/61w7qOS9j6L._SX679_.jpg","https://m.media-amazon.com/images/I/716BrAb0tOL._SX679_.jpg","https://m.media-amazon.com/images/I/61YYLHymZ7L._SX679_.jpg","https://m.media-amazon.com/images/I/61ppG2iM9cL._SX679_.jpg"],detail:{name:"boAt Airdopes Sonik in Ear TWS Earbuds with 60 hrs Playback, IWP Tech, Quad Mics with ENx, ASAP Charge, 11 mm Drivers, BT v5.3 & IPX4 Sweat and Splash Resistance(Thunder Black)",price:99,mrp:1273,discount:86},shot:img3},
    {images:["https://m.media-amazon.com/images/I/61ndjW1csiL._SX679_.jpg","https://m.media-amazon.com/images/I/81ynlPViLkL._SX679_.jpg","https://m.media-amazon.com/images/I/81YyDWfwNFL._SX679_.jpg","https://m.media-amazon.com/images/I/71G8PgHaDwL._SX679_.jpg","https://m.media-amazon.com/images/I/71C43hZHtiL._SX679_.jpg"],detail:{name:"boAt Newly Launched Airdopes 300 TWS in Ear Earbuds w/ 4 Mics AI-ENx™, Spatial Audio, 50 hrs Playtime, Multipoint Connection, ASAP™ Charge, Beast™ Mode, Hearables App Support(Gunmetal Black)",price:79,mrp:1399,discount:69},shot:img4},
    {images:["https://m.media-amazon.com/images/I/51h7CQTRJ1L._SX679_.jpg","https://m.media-amazon.com/images/I/518JdWAXvJL._SX679_.jpg","https://m.media-amazon.com/images/I/41EpveO7uQL._SX679_.jpg","https://m.media-amazon.com/images/I/61XHerhMdaL._SX679_.jpg","https://m.media-amazon.com/images/I/51wXDR0u4uL._SX679_.jpg"],detail:{name:"OnePlus Buds 3 in Ear TWS Bluetooth Earbuds with Upto 49dB Smart Adaptive Noise Cancellation,Hi-Res Sound Quality,Sliding Volume Control,10mins for 7Hours Fast Charging with Upto 44Hrs Playback",price:119,mrp:5499,discount:89},shot:img5},
    {images:["https://m.media-amazon.com/images/I/71NCWaRjkPL._SX679_.jpg","https://m.media-amazon.com/images/I/81EP4ka4GmL._SX679_.jpg","https://m.media-amazon.com/images/I/813-vAi8k8L._SX679_.jpg","https://m.media-amazon.com/images/I/71jErenEM2L._SX679_.jpg","https://m.media-amazon.com/images/I/819xfgWULQL._SX679_.jpg"],detail:{name:"Boult Audio Z25 True Wireless in Ear Earbuds with 32H Playtime, 45ms Low Latency, Type-C Fast Charging (10=150Mins), Made in India, Zen ENC Mic, 13mm Bass Drivers, Bluetooth 5.3 Ear Buds (Blue Moss)",price:99,mrp:899,discount:89},shot:img6},
    {images:["https://m.media-amazon.com/images/I/71efTn0Ea-L._SX679_.jpg","https://m.media-amazon.com/images/I/81SZr3yl0SL._SX679_.jpg","https://m.media-amazon.com/images/I/81s9UfE2w+L._SX679_.jpg","https://m.media-amazon.com/images/I/81UqpvzM+7L._SX679_.jpg","https://m.media-amazon.com/images/I/91JegL6o-JL._SX679_.jpg"],detail:{name:"Boult Audio Z60 Truly Wireless in Ear Earbuds with 60H Playtime, 4 Mics ENC Clear Calling, 50ms Low Latency Gaming, 13mm Bass Driver, Type-C Fast Charging, IPX5 Ear Buds Bluetooth 5.3 (Spring Green)",price:119,mrp:899,discount:79},shot:img7},
    {images:["https://m.media-amazon.com/images/I/616vhKZ+1ML._SX679_.jpg","https://m.media-amazon.com/images/I/91N65Y7vIpL._SX679_.jpg","https://m.media-amazon.com/images/I/81-4F0UUA2L._SX679_.jpg","https://m.media-amazon.com/images/I/816K4ohwRRL._SX679_.jpg","https://m.media-amazon.com/images/I/81swCnCmM4L._SX679_.jpg"],detail:{name:"JBL Tune Beam in Ear Wireless TWS Earbuds with Mic, ANC Earbuds, Customized Extra Bass with Headphones App, 48 Hrs Battery, Quick Charge, 4-Mics, IP54, Ambient Aware & Talk-Thru, Bluetooth 5.3 (Blue)",price:299,mrp:5498,discount:99},shot:img8},
    {images:["https://m.media-amazon.com/images/I/61qAuwvrTuL._SX679_.jpg","https://m.media-amazon.com/images/I/61U6At7IDfL._SX679_.jpg","https://m.media-amazon.com/images/I/615+LM9QiqL._SX679_.jpg","https://m.media-amazon.com/images/I/61rbD9Tg0oL._SX679_.jpg","https://m.media-amazon.com/images/I/71LApOJY8iL._SX679_.jpg"],detail:{name:"boAt Airdopes Max in Ear TWS Earbuds with 100 HRS Playtime, Quad Mics with ENx Tech, Beast Mode(50ms Low Latency), ASAP Charge, Bluetooth v5.3 and IWP(Ivory White)",price:159,mrp:1478,discount:80},shot:img9},
    {images:["https://m.media-amazon.com/images/I/61v2fXcBufL._SX679_.jpg","https://m.media-amazon.com/images/I/81SZr3yl0SL._SX679_.jpg","https://m.media-amazon.com/images/I/71RHG5pMxVL._SX679_.jpg","https://m.media-amazon.com/images/I/810ThOZbKpL._SX679_.jpg","https://m.media-amazon.com/images/I/81IDJb0husL._SX679_.jpg"],detail:{name:"Boult Audio Z60 Truly Wireless in Ear Earbuds with 60H Playtime, 4 Mics ENC Clear Calling, 50ms Low Latency Gaming, 13mm Bass Driver, Type-C Fast Charging, IPX5 ear buds TWS Bluetooth 5.3(Raven Black)",price:119,mrp:899,discount:76},shot:img10},
    {images:["https://m.media-amazon.com/images/I/31K0TGXVovL._SX679.jpg","https://m.media-amazon.com/images/I/61pXNw6JpLL._SX679_.jpg","https://m.media-amazon.com/images/I/619AhAz3GCL._SX679_.jpg","https://m.media-amazon.com/images/I/71h6YKnBjvL._SX679_.jpg","https://m.media-amazon.com/images/I/51UFCmurtZL._SX679_.jpg"],detail:{name:"JBL Tune 760NC, Wireless Over Ear Active Noise Cancellation Headphones with Mic, Upto 50 Hours Playtime, Multi-Device Connectivity, Pure Bass, AUX & Voice Assistant Support for Mobile Phones (Black)",price:599,mrp:4998,discount:85},shot:img11},
    {images:["https://m.media-amazon.com/images/I/71cBZJsl+EL._SX679_.jpg","https://m.media-amazon.com/images/I/81nKL4v5EiL._SX679_.jpg","https://m.media-amazon.com/images/I/81+pCe-PR8L._SX679_.jpg","https://m.media-amazon.com/images/I/71TdaIPWSBL._SX679_.jpg","https://m.media-amazon.com/images/I/812yQYC8IdL._SX679_.jpg"],detail:{name:"Boult Audio Maverick with 35H Playtime, 45ms Xtreme Low Latency Mode, Quad Mic ENC, Type C Fast Charging (10Mins=120Mins), 10mm Drivers, BT 5.3, IPX5 Airbass True Wireless in Ear Earbuds (Black)",price:99,mrp:899,discount:58},shot:img12},
    {images:["https://m.media-amazon.com/images/I/61usqWQl07L._SX679_.jpg","https://m.media-amazon.com/images/I/81-7gRLN4BL._SX679_.jpg","https://m.media-amazon.com/images/I/81HlwQAU+AL._SX679_.jpg","https://m.media-amazon.com/images/I/81TtCoCnewL._SX679_.jpg","https://m.media-amazon.com/images/I/71V1VjW-jDL._SX679_.jpg"],detail:{name:"Boult Audio Newly Launched K20 Bluetooth Truly Wireless in Ear Earbuds with 32H Playtime, 4 Mics Clear Calling ENC, 45ms Low Latency Gaming TWS, 13mm Bass Drivers Ear Buds Headphones 5.3 (Black)",price:89,mrp:799,discount:98},shot:img13},
    {images:["https://m.media-amazon.com/images/I/61nF5ekaaPL._SX679_.jpg","https://m.media-amazon.com/images/I/71kJdA4df5L._SX679_.jpg","https://m.media-amazon.com/images/I/71RHfdrWBqL._SX679_.jpg","https://m.media-amazon.com/images/I/81A8WQhH51L._SX679_.jpg","https://m.media-amazon.com/images/I/71H8wNjD7PL._SX679_.jpg"],detail:{name:"BLUE STAR 5 Star 1.5 Ton Convertible 5 in 1 Cooling Inverter Split Air Conditioner (BLUE STAR AC IC518TNUR, IU)",price:4189,mrp:56111,discount:89},shot:img14},
    {images:["https://m.media-amazon.com/images/I/81gC7frRJyL._SX679_.jpg","https://m.media-amazon.com/images/I/71zMz9YT0qL._SX679_.jpg","https://m.media-amazon.com/images/I/91qLD9hZWkL._SX679_.jpg","https://m.media-amazon.com/images/I/71IXi+dxjHL._SX679_.jpg","https://m.media-amazon.com/images/I/71TvEYgcolL._SX679_.jpg"],detail:{name:"Apple iPad Pro 11″ (4th Generation): with M2 chip, Liquid Retina Display, 128GB, Wi-Fi 6E, 12MP front/12MP and 10MP Back Cameras, Face ID, All-Day Battery Life – Space Grey",price:8199,mrp:79900,discount:99},shot:img15},
    {images:["https://m.media-amazon.com/images/I/81CgtwSII3L._SX679_.jpg","https://m.media-amazon.com/images/I/51nZVUtGqOL._SX679_.jpg","https://m.media-amazon.com/images/I/715zWp1q7rL._SX679_.jpg","https://m.media-amazon.com/images/I/71g7dxYXiOL._SX679_.jpg","https://m.media-amazon.com/images/I/81MDZsYTIoL._SX679_.jpg"],detail:{name:"Apple iPhone 15 Pro (256 GB) - Natural Titanium",price:28980,mrp:137990,discount:89},shot:img16},
    {images:["https://m.media-amazon.com/images/I/81vxWpPpgNL._SX679_.jpg","https://m.media-amazon.com/images/I/71JLhofuYJL._SX679_.jpg","https://m.media-amazon.com/images/I/71ZdFihN4YL._SX679_.jpg","https://m.media-amazon.com/images/I/71E-ptCgHcL._SX679_.jpg","https://m.media-amazon.com/images/I/71h5+9-CgbL._SX679_.jpg"],detail:{name:"Samsung Galaxy S24 Ultra 5G AI Smartphone (Titanium Gray, 12GB, 512GB Storage)",price:15949,mrp:139999,discount:82},shot:img17},
    {images:["https://m.media-amazon.com/images/I/71h2c+bB-fL._SX679_.jpg","https://m.media-amazon.com/images/I/71pr77gMM3L._SX679_.jpg","https://m.media-amazon.com/images/I/81Emg2mz6hL._SX679_.jpg","https://m.media-amazon.com/images/I/81BDl+Zy5cL._SX679_.jpg","https://m.media-amazon.com/images/I/71jBMiXs9vL._SX679_.jpg"],detail:{name:"JBL Partybox 110 | Wireless Bluetooth Party Speaker| 160W Monstrous Pro Sound| Dynamic Light Show| Upto 12Hrs Playtime | Built-in Powerbank | Guitar & Mic Support PartyBox App (Black)",price:719,mrp:27969,discount:99},shot:img18},
    {images:["https://m.media-amazon.com/images/I/51iv-60kraL._SX679_.jpg","https://m.media-amazon.com/images/I/71qjUzUt+ML._SX679_.jpg","https://m.media-amazon.com/images/I/71kvEYMIYFL._SX679_.jpg","https://m.media-amazon.com/images/I/61TvnnwtqZL._SX679_.jpg","https://m.media-amazon.com/images/I/51yteiP577L._SX679_.jpg"],detail:{name:"OnePlus 11R 5G (Sonic Black, 16GB RAM, 256GB Storage)",price:3219,mrp:44999,discount:99},shot:img19},
    {images:["https://m.media-amazon.com/images/I/512r6mCuztL._SX679_.jpg","https://m.media-amazon.com/images/I/71X1KcKGbSL._SX679_.jpg","https://m.media-amazon.com/images/I/61ptYuF6AVL._SX679_.jpg","https://m.media-amazon.com/images/I/71Q4oM43waL._SX679_.jpg","https://m.media-amazon.com/images/I/71F-D-bwC-L._SX679_.jpg"],detail:{name:"Philips Hair Dryer - Powerful drying with less heat I 6 Styling Options for Versatile Salon like looks I 2100 W I Men and Women I Styling attachment | Cool Shot | Advanced Ionic Care for Frizz-Free hair I ThermoProtect Care for Minimised Damage BHD356/10",price:713,mrp:2499,discount:79},shot:img20},


]