import React, { useState } from 'react'
import Head1 from '../../components/header/Head1'
import Foot1 from '../../components/header/Foot1'
import './address.css'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
const Address = () => {
  const [name,setName]=useState("")  
  const [mobile,setMobile]=useState("")
  const [pincode,setPin]=useState("")
  const [flat,setFlat]=useState("")
  const [area,setArea]=useState("")
  const [landmark,setLand]=useState("")
  const [city,setCity]=useState("")
  const [state,setState]=useState("")

const navigate=useNavigate()
const addressClick=()=>{
if(name==="" || mobile==="" || pincode==="" || flat==="" || area==="" || landmark==="" || city==="" || state===""){
  toast.error("Please Fill All The Information")
}
else{
  localStorage.setItem("address",JSON.stringify({
    name:name,
    mobile:mobile,
    pincode:pincode,
    flat:flat,
    area:area,
    landmark:landmark,
    city:city,
    state:state
  }))
  navigate("/order")
}
}

  return (
    <div>
      <Head1/>
    <div className="address">
      <p>Add A New Address</p>
      <div className="form">
        <div className="country">
          <p>Country</p>
          <select name="" id="">
            <option value="india">india</option>
          </select>
        </div>
        <div className="country">
          <p>Full Name</p>
          <input type="text" placeholder='Enter Your Full Name' value={name} onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div className="country">
          <p>Mobile</p>
          <input type="text" placeholder='10-digit mobile number without prefixes' value={mobile} onChange={(e)=>setMobile(e.target.value)}/>
        </div>
        <div className="country">
          <p>Pin Code</p>
          <input type="text" placeholder='6-digits Pin Code' value={pincode} onChange={(e)=>setPin(e.target.value)}/>
        </div>
        <div className="country">
          <p>Flat, House no., Building, Company</p>
          <input type="text" value={flat} onChange={(e)=>setFlat(e.target.value)}/>
        </div>
        <div className="country">
          <p>Area, Street, Sector, Village</p>
          <input type="text" value={area} onChange={(e)=>setArea(e.target.value)}/>
        </div>
        <div className="country">
          <p>Landmark</p>
          <input type="text" placeholder='e.g near appolo hospital' value={landmark} onChange={(e)=>setLand(e.target.value)}/>
        </div>
        <div className="country">
          <p>Town/City</p>
          <input type="text" value={city} onChange={(e)=>setCity(e.target.value)}/>
        </div>
        <div className="country">
          <p>State</p>
          <select name="state" placeholder="State" value={state} onChange={(e)=>setState(e.target.value)}>
                            <option value="">Select a state</option>
                            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                            <option value="Daman and Diu">Daman and Diu</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Orissa">Orissa</option>
                            <option value="Pondicherry">Pondicherry</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttaranchal">Uttaranchal</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="West Bengal">West Bengal</option>
                        </select>
        </div>
        <div className="check">
          <input type="checkbox" name="" id="" />
          <p>Make this my default address</p>
        </div>
        <div className="ins">
          <p>Add delivery instructions</p>
          <p>Preferences are used to plan your delivery. However, shipments can sometimes arrive early or later than planned.</p>
        </div>
        <div className="btn" style={{width:'100%',padding:0}}>
          <button onClick={addressClick}>Use This Address</button>
        </div>

      </div>
    </div>

      <Foot1/>
    </div>
  )
}

export default Address
