import React from 'react'

const Head1 = () => {
  return (
    <div className='head1'>
      <div className="logo">
            <img src="https://pngimg.com/d/amazon_PNG25.png" alt="" />
        </div>
    </div>
  )
}

export default Head1
