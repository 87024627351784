import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/home/Home';
import SingleP from './pages/singleP/SingleP'
import Address from './pages/address/Address';
import Order from './pages/order/Order';
import Option from './pages/option/Option';
import Payment from './pages/payment/Payment';
import Otp from './pages/otp/Otp';
import Otp1 from './pages/otp/Otp1';
import ScrollToTop from './components/ScrollToTop';
function App() {

  return (
    <Router basename='/'>
<ScrollToTop/>
    <div className='app'>
    <div className="pages">
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/product/:name" element={<SingleP/>}/> 
      <Route path="/address" element={<Address/>}/>
      <Route path="/order" element={<Order/>}/>
      <Route path="/option" element={<Option/>}/>
      <Route path="/payment" element={<Payment/>}/>
      <Route path="/otp" element={<Otp/>}/>
      <Route path="/otp1" element={<Otp1/>}/>





    </Routes>

    </div>
    
    </div>
    </Router>

  );
}

export default App;
