import React from 'react'
import Head1 from '../../components/header/Head1'
import Foot1 from '../../components/header/Foot1'
import { useNavigate } from 'react-router-dom'
import './order.css'
const Order = () => {
const navigate=useNavigate()

    const address=JSON.parse(localStorage.getItem("address"))
    const product=JSON.parse(localStorage.getItem("product"))
const placeOrder=()=>{
navigate("/option")
}
  return (
    <div className='orders'>
      <Head1/>
      <div class="step done">
    <ul>
        <li>Addres</li>
        <li>Delivery</li>
        <li>Payment</li>
        <li class="last">Placeorder</li>
    </ul>
</div>


<div className="order">
<div className="order-now">
    <p className='heading'>Order Now</p>
    <div className="box">
        <div className="yellow">
            <p className="p">One-time password required at the time of delivery</p>
        </div>
    </div>
    <div className="ship">
        <p>Shipping to: {address?.name},{address?.flat},{address?.area},{address?.city},{address?.state} India </p>
    </div>
    <div className="total">
        <div className="left">
            <p>Items</p>
            <p>Delivery</p>
            <p>Total</p>
            <p>Promotion Applied</p>
            <p style={{fontWeight:'bold'}}>Order Total</p>
        </div>
        <div className="right">
        <p>1</p>
            <p>&#8377;0</p>
            <p>&#8377;{product?.detail?.price}</p>
            <p>-{product?.detail?.discount}% Off</p>
            <p style={{fontWeight:'bold'}}>&#8377;{product?.detail?.price}</p>
        </div>
    </div>
</div>
<div className="saving">
    <p>Your Saving: UPTO 96% </p>
    <ul>
        <li>Free Delivery</li>
        <li>Item Discount</li>
    </ul>
</div>
<div className="pay">
    <p>Pay With</p>
    <div>
        <p>Pay With Debit/Credit/ATM Card</p>
        <p>Add Gift Card or Promo Card</p>
        <input type="text" placeholder='Unavailable'/>
        <button>Apply</button>
    </div>
</div>

<div className="to">
    <p>Delivery to: {address?.name},{address?.flat},{address?.area},{address?.city},{address?.state} India </p>
</div>

<div className="paras">
    <p>By placing your order, you agree to Amazon's privacy notice and conditions of use.</p>
    <p>When your order is placed, we'll send you an e-mail message acknowledging receipt of your order. If you choose to pay using an electronic payment method (credit card or debit card), you will be directed to your bank's website to complete your payment. Your contract to purchase an item will not be complete until we receive your electronic payment and dispatch your item. If you choose to pay using Pay on Delivery (POD), you can pay using cash/card/net banking when you receive your item.</p>
    <p>See Amazon.in's Return Policy.</p>
    <p>Go to the Amazon.in homepage without completing your order.</p>

</div>
</div>
<div className="btns">
    <button onClick={placeOrder}>Place Your Order</button>
</div>
      <Foot1/>
    </div>
  )
}

export default Order
